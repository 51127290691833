.table-container {
  overflow-x: auto; /* Enable horizontal scrolling on small screens */
}

@media (max-width: 767px) {
  /* Styles for screens up to 767px (mobile devices) */
  .table-container {
    width: 100%; /* Make the table container full width */
  }

  .ant-table table {
    width: 100% !important; /* Make the table fill the container */
  }

  .ant-table-thead th {
    white-space: nowrap; /* Prevent table header text from wrapping */
  }

  .ant-table-row-expand-icon-cell,
  .ant-table-row-expand-icon {
    display: none; /* Hide the expand/collapse icons */
  }
}
