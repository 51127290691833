@tailwind base;
@tailwind components;
@tailwind utilities;

.hero {
  background-image:
  url('../images/HomeImage.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.exhibition {
  background-image:
  url('../images/homeImage2.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
